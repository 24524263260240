.get-involved {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    gap: 20px;
    padding: 5vh;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.toggle-button,
.toggle-button.active {
    background-color: red;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 10px;
    font-size: 18px;
    margin: 0 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.toggle-button:hover,
.toggle-button.active:hover {
    background-color: rgb(200, 0, 0);
    border-radius: 10px;
    transform: scale(1.05);
}

.airtable-involved-embed {
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.seeker-form {
    height: 1050px;
}

.provider-form {
    height: 750px;
}

@media (min-width: 768px) {
    .get-involved {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 40px;
    }

    .airtable-involved-embed {
        max-width: 45%;
    }
}
