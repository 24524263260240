body {
    margin: 0; 
    padding: 0; 
}

.red-block {
    background-color: red; 
    height: 5vh; 
}

.mission {
    display: flex; 
    align-items: center;
    background-color: white;
    text-align: left; 
}

.mission-image {
    flex: 1; 
    height: 100%; 
    object-fit: cover;
    margin: 0; 
}

.mission-text {
    flex: 1; 
    padding-left: 20px; 
}

.mission h2 {
    font-size: 2.5em;
    margin-bottom: 20px; 
}

.mission p {
    font-size: 1.2em;
}
