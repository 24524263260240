body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.red-block {
    background-color: red;
    height: 5vh;
}

.testimonials {
    padding: 20px;
    background-color: white;
    text-align: center;
}

.testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.flexbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.card {
    height: 70vh;
    width: 30%;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.card:hover {
    transform: scale(1.02);
}

.card img {
    height: 50%;
    object-fit: cover;
}

.card-body {
    text-align: center;
}

.card-text {
    padding: 20px;
    font-style: italic;
    color: #666;
}

.card-img-top {
    margin: 25px;
    border-radius: 10px;
}
