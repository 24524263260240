.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    gap: 20px;
    padding: 5vh;
}

.airtable-contact-embed {
    max-width: 500px;
    height: 755px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 100%;
}

.airtable-contact-embed{
    background: transparent;
    border: 0px;
}