/* Testing:
 *{
    margin: 0;
    padding: 0;
} */

section {
    margin: 0; 
    padding: 0;
}
