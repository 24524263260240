.resources {
    padding: 20px;
}

.resource-block {
    display: flex; 
    align-items: center; 
    margin-bottom: 20px; 
}

.image {
    flex: 0 0 40%; 
    max-width: 300px;
    width: 100%; 
    height: auto;
}

.content {
    flex: 1; 
    padding-left: 20px; 
}

.reverse {
    flex-direction: row-reverse; 
}

.resources h2 {
    color: #333;
}

.resources p {
    color: #666;
}
